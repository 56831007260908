<template>
    <CCardBody class="d-flex flex-column">
        <CRow>
            <CCol>								
                <h1>Verify Email Address</h1>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12">								
                <p class="text-muted">We have sent an email with a verification code to <strong>{{ email }}</strong>. Enter the verification code below. 
                    If you haven't received an email, please check whether the email address is correct and look into your spam folder.
                </p>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="7">
                <CForm @submit.prevent="submit">
                    <CInput placeholder="Code" :disabled="loading" maxlength="6"
                            style="height: 42px"
                            invalidFeedback="The field is required"
                            required
                            v-model="code"
                    >
                    </CInput>
                    <div class="d-flex flex-row">
                        <CButton color="primary" type="submit" :disabled="(loading || !code)"><CSpinner v-if="loading" size="sm" class="mr-2"></CSpinner>Verify</CButton> 
                        <CLink v-if="!skipLogin" style="margin-left: auto" class="mr-1 align-self-center" to="login" :disabled="loading">Back to Login</CLink>   
                    </div>
                </CForm>
            </CCol>
        </CRow>
        <CRow class="justify-self-end" style="margin-top: auto;">
            <CCol>
                <CAlert :show="true" color="danger" :fade="false" :class="['mt-3', 'mb-2', errorMessage ? 'visible' : 'invisible']">{{ errorMessage }}</CAlert>
            </CCol>
        </CRow>		
    </CCardBody>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { authAction } from '@/store/modules/auth';
import { getErrorMessage } from "@/utils";
import router from '@/router';

export default {
    props: {
        loginLink: Boolean
    },
    created() {
        if (!this.email) router.replace('/password');
    },
    data() {
        return {            
            code: null,            
            loading: false,
            errorMessage: null
        }
    },
    computed: {
        ...mapGetters({'email':'email'}),
        skipLogin() {
            return this.loginLink === false || this.loginLink === 'false';
        }
    },
    methods: {
        ...mapActions({ verify: authAction.VERIFY }),
        async submit() {
            this.loading = true;
            if (this.email && this.code) {
                try {
                    await this.verify({email: this.email, code: this.code});
                    router.replace({path:'/set-password', query: { login: this.loginLink }});
                    return;
                }
                catch (e) {
                    console.error(e);
                    this.handleError(e);
                }
            }
            this.loading = false;
        },
        handleError(e) {
            if (e.response && e.response.data && e.response.data.errorCode === 1) {
                this.errorMessage = "Request failed. The code could not be verified.";
                const self = this;
                setTimeout(() => {
                    if (self) self.loading = false;
                }, 2000);
                return;
            }
            this.errorMessage = getErrorMessage(e);            
        }
    }
}
</script>
